'use client';

import './globals.css';
import GoogleTagManager from '@magicul/next-google-tag-manager';
import dynamic from 'next/dynamic';
import { fetchGlobalData } from '@/features/global';
import { GlobalProvider } from '@/shared/context';
import { NavigationEvents } from '@/shared/navigation-events';


const WebVitals = dynamic(() => import('@/shared/ui/web-vitals').then(module => ({ default: module.WebVitals })));

export default async function RootLayout({
  children,
}: {
  children: React.ReactNode
}) {

  const globalData = await fetchGlobalData();
  
  
  return (
        <html lang="ru">
        <head>
            <meta name="format-detection" content="telephone=no" />
            <style
                dangerouslySetInnerHTML={{ __html: '@import url(\'https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600&family=Raleway:wght@200;300;400;500&display=swap\');' }}
            />
        </head>
        <NavigationEvents />
        <body className="inter">
        <GoogleTagManager id="GTM-P4LS952K" />

        <GlobalProvider value={globalData}>
            {process.env.NODE_ENV === 'development' && <WebVitals/>}
            {children}
        </GlobalProvider>
        </body>
        </html>
  );
}

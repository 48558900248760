'use client';

import { useEffect } from 'react';
import Cookies from 'js-cookie';
import { usePathname } from 'next/navigation';

export function NavigationEvents() {
  const pathname = usePathname();

  useEffect(() => {

    return () => {
      if (navigator.cookieEnabled) {
        Cookies.set('referer', pathname);
      }
    };

  }, [pathname]);

  return null;
}
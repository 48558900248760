import { IContacts } from '@/shared/interfaces/contacts';
import { IFooter } from '@/shared/interfaces/footer';
import { IHeader } from '@/shared/interfaces/header';
import { IRequest } from '@/shared/interfaces/request';
import { fetchClient } from '@/utils/apiClient';


export const fetchHeader = async () => {
  try {
    const headerData = await fetchClient.get<IRequest<Partial<IHeader>>>('/template.v1.getHeader');

    return headerData.result.response;
  } catch (e: any) {
    console.log(e);
    return { error: e.message };
  }
};

export const fetchFooter = async () => {
  try {
    const footerData = await fetchClient.get<IRequest<Partial<IFooter>>>('/template.v1.getFooter');

    return footerData.result.response;
  } catch (e: any) {
    return { error: e.message };
  }
};

export const fetchContacts = async () => {
  try {
    const contactsData = await fetchClient.get<IRequest<Partial<IContacts>>>('/contacts.v1.getContacts');

    return contactsData.result.response;
  } catch (e: any) {
    return { error: e.message };
  }
};

export const fetchGlobalData = async () => {
  const footerData = await fetchFooter();
  const contactsData = await fetchContacts();
  const headerData = await fetchHeader();

  return {
    header: headerData,
    footer: footerData,
    contacts: contactsData,
  };
};